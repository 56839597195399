<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :create-route="{ name: 'productMasterForm' }"
      :create-label="'Buat Produk'"
      @delete-selected="deleteSelected"
      @per-page-change="perPage = $event"
    >
      <template #button-export-items>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="openModalExportMaster()"
        >
          <Feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-25">Export</span>
        </b-button>
      </template>
      <template #dropdown-items>
        <b-dropdown-item
          :disabled="selectedDatas.length === 0"
          variant="primary"
          @click="copyProductIds"
        >
          <Feather-icon icon="CopyIcon" />
          Salin ke Marketplace ({{ selectedDatas.length }})
        </b-dropdown-item>
      </template>
    </TableHeader>

    <b-table-simple
      striped
      responsive
      style="zoom: 85%"
      class="position-relative"
    >
      <b-thead sticky-column>
        <b-tr>
          <b-th class="bg-transparent">
            <b-form-checkbox v-model="selectAll" @change="selectAllRows">
            </b-form-checkbox>
          </b-th>
          <b-th class="bg-transparent" style="width: 40%">Nama produk</b-th>
          <b-th class="bg-transparent">Harga</b-th>
          <b-th class="bg-transparent">Stok</b-th>
          <b-th class="bg-transparent" style="width: 20%">SKU Terikat</b-th>
          <b-th class="bg-transparent" style="width: 20%">Toko Terikat</b-th>
          <b-th class="bg-transparent"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr v-for="(item, index) in tableData" :key="index" :item="item">
          <b-td>
            <b-form-checkbox
              v-model="selectedDatas"
              :value="item"
            ></b-form-checkbox>
          </b-td>
          <b-td>
            <b-media vertical-align="center">
              <template #aside>
                <b-img
                  :alt="item.title"
                  :src="item.firstImage"
                  style="
                    object-fit: cover;
                    object-position: center;
                    width: 70px;
                    height: 70px;
                  "
                  thumbnail
                ></b-img>
              </template>
              <h5
                class="mt-0 mb-1 text-primary"
                style="cursor: pointer"
                @click="gotoEdit(item._id)"
              >
                <b>{{ item.title }}</b>
              </h5>
              <small class="mb-0"><b>SKU :</b> {{ item.sku }}</small>
            </b-media>
          </b-td>
          <b-td>
            <div class="d-flex align-items-center justify-content-between">
              <span>{{ item.priceRupiah }}</span>

              <b-button
                variant="link"
                title="Ubah Harga Produk"
                size="sm"
                @click="changeProductPrice(item._id)"
              >
                <Feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </b-td>
          <b-td>
            <div class="d-flex align-items-center justify-content-between">
              <span>{{ item.stock }}</span>

              <b-button
                variant="link"
                title="Ubah Stok Produk"
                size="sm"
                @click="changeProductStock(item._id)"
              >
                <Feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </b-td>
          <b-td>
            <b-button
              variant="light"
              size="sm"
              title="SKU Terikat"
              @click="openModalBoundSKU(item._id)"
            >
              <div class="d-flex align-items-center">
                <b class="text-success mr-1">[ {{ item.totalBoundSKU }} ]</b>
                <span class="mr-1">SKU</span>
                <Feather-icon icon="PlusIcon" />
              </div>
            </b-button>
          </b-td>

          <b-td>
            <b-button
              variant="light"
              size="sm"
              @click="openModalBoundSKU(item._id)"
            >
              <div class="d-flex align-items-center">
                <b class="text-success mr-1">[ {{ item.totalBoundShop }} ]</b>
                <span class="mr-1">Toko</span>
                <Feather-icon icon="PlusIcon" />
              </div>
            </b-button>
          </b-td>
          <b-td>
            <b-button-group>
              <!-- salin ke marketplace -->
              <b-button
                title="Salin ke Toko Lain"
                variant="outline-primary"
                size="sm"
                @click="copySingleProduct(item._id)"
              >
                <Feather-icon icon="CopyIcon" size="16" />
              </b-button>
              <b-button
                title="Hapus produk"
                variant="outline-danger"
                size="sm"
                @click="deleteProduct(item._id)"
              >
                <Feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </b-button-group>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody v-else>
        <b-tr>
          <b-td colspan="7" class="text-center"> No data found </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <component :is="modalBindSKUComponent" v-bind="{ productData }" />
    <component :is="modalExportMasterComponent" v-bind="{ productData }" />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BThead,
  BTh,
  BFormCheckbox,
  BButton,
  BModal,
  BContainer,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BButtonGroup,
  VBTooltip,
  BMedia,
  BImg,
  BLink,
  BDropdownItem,
} from 'bootstrap-vue';
import axios from '@axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { defineComponent } from '@vue/composition-api';
import moment from 'moment';
import SidebarSearch from '@components/SidebarSearch.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';
import TableHeader from '@/components/TableHeader.vue';

export default defineComponent({
  name: 'VariablesList',
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BThead,
    BTh,
    BFormCheckbox,
    BButton,
    BModal,
    BContainer,
    BFormGroup,
    BFormInput,
    ToastificationContent,
    BFormTextarea,
    BOverlay,
    BButtonGroup,
    SidebarSearch,
    EmptyTableRow,
    TableHeader,
    BMedia,
    BImg,
    BLink,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      productData: {},
      modalBindSKUComponent: () =>
        import('./modal-bound-sku/ModalBoundSKU.vue'),
      modalExportMasterComponent: () =>
        import('./ModalExportMaster.vue'),
      searchComponent: [
        {
          name: 'search',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: '',
        },
        {
          label: 'Urutkan berdasarkan',
          name: 'sortBy',
          type: 'select',
          value: null,
          options: [
            { value: '1', text: 'Tanggal (Terbaru)' },
            { value: '2', text: 'Tanggal (Terlama)' },
          ],
        },
      ],
    };
  },
  watch: {
    perPage() {
      this.searchData();
    },
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      this.isBusy = true;

      const params = {
        page: this.currentPage,
        limit: this.perPage,
      };

      if (this.search) {
        // assign all keys of search to params
        Object.assign(params, this.search);
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`product?${url}`);

      this.tableData = [...this.tableData, ...response.data.data];

      const rupiah = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });

      if (this.tableData.length > 0) {
        this.tableData = this.tableData.map(item => {
          const newItem = item;
          newItem.balanceReal = item.price;
          newItem.priceRupiah = rupiah.format(item.price);
          newItem.updatedAt = moment(item.updatedAt).format('DD-MM-YYYY HH:mm');
          const [firstImage] = item.images;
          newItem.firstImage = firstImage;
          return newItem;
        });
      }

      // check image is 404 or not using promise
      this.tableData = await Promise.all(
        this.tableData.map(async item => {
          const newItem = item;
          newItem.firstImage = await this.checkImage(
            item.firstImage,
            item.title,
          );
          return newItem;
        }),
      );

      this.totalRows = response.data.total;
      this.currentPage += 1;

      this.isBusy = false;
    },

    async checkImage(url, title) {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve(url);
        img.onerror = () =>
          resolve(`https://cdn.statically.io/avatar/${title}`);
        img.src = url;
      });
    },

    async deleteSelected(sel) {
      this.$swal({
        title: 'Apakah Anda yakin?',
        text: `Anda tidak akan dapat mengembalikan ${sel} produk ini!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak, batalkan!',
      }).then(async result => {
        if (result.value) {
          this.tableData = this.tableData.filter(
            item => !this.selectedDatas.includes(item),
          );

          // eslint-disable-next-line no-underscore-dangle
          const ids = this.selectedDatas.map(item => item._id);

          await axios
            .post('product/del/multiple', {
              ids,
            })
            .then(() => {
              this.tableData = this.tableData.filter(
                item => !this.selectedDatas.includes(item),
              );
            });

          if (this.tableData.length === 0) {
            this.currentPage = 1;
            this.fetchData();
          }

          this.selectedDatas = [];
          this.selectAll = false;

          this.$swal({
            title: 'Berhasil!',
            text: `${sel} produk telah dihapus.`,
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        }
      });
    },

    formatAmount() {
      // Format the number as a currency
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });

      this.balanceForm.balanceRp = formatter.format(this.balanceForm.balance);
    },

    handleScroll() {
      const isAtBottom =
        document.documentElement.scrollHeight -
          (window.innerHeight + window.scrollY) <=
        1;

      if (isAtBottom && !this.isFetching) {
        // Set the fetching flag to true to prevent multiple fetch calls
        this.isFetching = true;

        // Simulate fetchData with a delay to demonstrate the concept
        this.fetchData();

        // Reset the fetching flag after the fetchData is complete
        setTimeout(() => {
          this.isFetching = false;
        }, 1500);
      }
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },

    gotoEdit(id) {
      this.$router.push({ name: 'productMasterEdit', params: { id } });
    },

    deleteProduct(id) {
      this.$swal({
        title: 'Apakah Anda yakin?',
        text: 'Anda tidak akan dapat mengembalikan produk ini!',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Tidak, batalkan!',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(async result => {
        if (result.value) {
          const response = await axios.delete(`product/${id}`);

          if (response && response.data && response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Produk telah dihapus.',
              },
            });

            this.tableData = this.tableData.filter(item => item._id !== id);
          }
        }
      });
    },

    changeProductPrice(id) {
      this.$swal({
        title: 'Ubah Harga Produk',
        input: 'text',
        inputPlaceholder: 'Masukkan harga produk',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValue: this.tableData.find(item => item._id === id).price,
        inputValidator: value => {
          if (!value) {
            return 'Harga produk tidak boleh kosong!';
          }

          if (Number(value) <= 0) {
            return 'Harga produk tidak boleh kurang atau sama dengan 0!';
          }

          // check is only contains number
          if (!/^\d+$/.test(value)) {
            return 'Harga produk hanya boleh berisi angka!';
          }
          return null;
        },
      }).then(async result => {
        if (result.value) {
          const response = await axios.put(`product/update-price/${id}`, {
            price: result.value,
          });

          if (response && response.data && response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Harga produk telah diubah.',
              },
            });

            this.tableData = this.tableData.map(item => {
              if (item._id === id) {
                item.price = result.value;
                item.priceRupiah = new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(result.value);
              }
              return item;
            });
          }
        }
      });
    },

    async changeProductStock(id) {
      this.$swal({
        title: 'Ubah Stok Produk',
        input: 'text',
        inputPlaceholder: 'Masukkan stok produk',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValue: this.tableData.find(item => item._id === id).stock,
        inputValidator: value => {
          if (!value) {
            return 'Stok produk tidak boleh kosong!';
          }

          // check is only contains number
          if (!/^\d+$/.test(value)) {
            return 'Stok produk hanya boleh berisi angka!';
          }
          return null;
        },
      }).then(async result => {
        if (result.value) {
          const response = await axios.put(`product/update-stock/${id}`, {
            stock: result.value,
          });

          if (response && response.data && response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Stok produk telah diubah.',
              },
            });

            this.tableData = this.tableData.map(item => {
              if (item._id === id) {
                item.stock = result.value;
              }
              return item;
            });
          }
        }
      });
    },

    openModalBoundSKU(id) {
      const data = this.tableData.find(item => item._id === id);
      this.productData = data;
      this.$bvModal.show('modal-bind-sku');
    },

    async openModalExportMaster() {
      this.productData = this.tableData;
      this.$bvModal.show('modal-export-master');
    },

    async searchData(search = null) {
      this.search = search || this.search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;
      await this.fetchData();
    },

    async copyProductIds() {
      await this.$store.dispatch('master/setProductIds', this.selectedDatas);
      this.$router.push({ name: 'productMasterCopy' });
    },

    async copySingleProduct(id) {
      await this.$store.dispatch('master/setProductIds', [
        this.tableData.find(item => item._id === id),
      ]);

      this.$router.push({ name: 'productMasterCopy' });
    },
  },
});
</script>

<style lang="scss">
.swal2-input {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
