<template>
  <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Tampilkan</label>
        <v-select
          v-model="perPageSelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="[10, 20, 30, 40, 50]"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50 select-size-sm w-15"
        />
        <label>Item</label>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex d-flex align-items-center justify-content-end">
          <b-button-group>
            <b-button
              v-b-toggle.sidebar-search
              :disabled="disableFilter"
              variant="primary"
              size="sm"
            >
              <Feather-icon icon="FilterIcon" /> Filter & Urutkan
            </b-button>
            <b-button
              v-if="hasCreateButton"
              variant="success"
              size="sm"
              :to="createRoute"
            >
              <Feather-icon icon="PlusIcon" />
              <span class="align-middle ml-25"> {{ createLabel }} </span>
            </b-button>
            <slot name="buttons-group"></slot>
            <slot name="button-export-items"></slot>
            <b-dropdown v-if="selectedLength > 0" right size="sm">
              <template #button-content>
                <Feather-icon icon="MoreVerticalIcon" />
              </template>
              
              <slot name="dropdown-items"></slot>
              <b-dropdown-divider v-if="!disableDelete" />
              <b-dropdown-item
                v-if="!disableDelete"
                variant="danger"
                size="sm"
                :disabled="selectedLength === 0"
                @click="deleteSelected"
                ><Feather-icon icon="Trash2Icon" /> Hapus ({{
                  selectedLength
                }})</b-dropdown-item
              >
              <!-- <b-dropdown-item>Item 2</b-dropdown-item>
              <b-dropdown-item>Item 3</b-dropdown-item> -->
            </b-dropdown>
          </b-button-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BButtonGroup,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    vSelect,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    selectedLength: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    createRoute: {
      type: Object,
      default: () => ({}),
    },
    hasCreateButton: {
      type: Boolean,
      default: true,
    },
    disableFilter: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    createLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      perPageSelected: this.perPage,
    };
  },
  watch: {
    perPageSelected(val) {
      this.$emit('per-page-change', val);
    },
  },
  methods: {
    deleteSelected() {
      if (this.selectedLength > 0) {
        this.$emit('delete-selected', this.selectedLength);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.w-15 {
  width: 15% !important;
}
</style>
