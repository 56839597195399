<template>
  <b-tbody>
    <b-tr>
      <b-td class="text-center" :colspan="colspan">
        <b-img
          fluid
          :src="imgUrl"
          alt="Empty Data"
          :style="styleZoomBasedOnScreenSize"
          class="pb-2 pt-2"
        />
        <br />
        <span class="text-muted">
          Tidak ada data yang ditemukan untuk ditampilkan, silahkan coba lagi
        </span>
      </b-td>
    </b-tr>
  </b-tbody>
</template>

<script>
import { BTbody, BTr, BTd, BImg } from 'bootstrap-vue';

export default {
  name: 'ContactImportExcel',
  components: {
    BTbody,
    BTr,
    BTd,
    BImg,
  },
  props: {
    colspan: {
      type: Number,
      default: 5,
    },
    externalImage: {
      type: String,
      default: 'undraw_empty_re_opql.svg',
    },
  },
  data() {
    return {
      // imgUrl: require('@/assets/images/pages/undraw_empty_re_opql.svg'),
      // eslint-disable-next-line import/no-dynamic-require
      imgUrl: require(`@/assets/images/pages/${this.externalImage}`),
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    styleZoomBasedOnScreenSize() {
      if (this.screenWidth < 768) {
        return 'zoom: 0.5';
      }

      if (this.screenWidth < 992) {
        return 'zoom: 0.7';
      }

      if (this.screenWidth < 1200) {
        return 'zoom: 0.37';
      }

      if (this.screenWidth < 1250) {
        return 'zoom: 0.16';
      }

      if (this.screenWidth < 1300) {
        return 'zoom: 0.41';
      }

      if (this.screenWidth < 1350) {
        return 'zoom: 0.43';
      }

      // if more than 1200

      return 'zoom: 0.8';
    },
  },
  mounted() {
    // Attach event listener for window resize
    window.addEventListener('resize', this.handleResize);

    // Initial call to set initial screen dimensions
    this.handleResize();
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // Update screen dimensions on resize
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>
