<template>
  <b-sidebar
    id="sidebar-search"
    right
    shadow
    :title="title ? title : 'Filter & Urutkan'"
  >
    <div v-if="components.length > 0" class="mx-2">
      <b-form-group
        v-for="(component, index) in components"
        :key="component.name"
        :label="component.label"
        label-size="sm"
      >
        <b-form-input
          v-if="component.type === 'text'"
          :id="component.name"
          v-model="formSearchDynamic[component.name]"
          size="sm"
          autocomplete="off"
          :placeholder="component.placeholder"
          :name="component.name"
          :value="component.value"
          :class="index === 0 ? 'mt-1' : ''"
        />
        <!-- select -->
        <b-form-select
          v-if="component.type === 'select'"
          :id="component.name"
          v-model="formSearchDynamic[component.name]"
          size="sm"
          :options="component.options"
        >
          <template #first>
            <b-form-select-option :value="null">
              {{ component.placeholder ? component.placeholder : 'Select' }}
            </b-form-select-option>
          </template>
        </b-form-select>

        <!-- select multiple -->
        <v-select
          v-if="component.type === 'select-multiple'"
          v-model="formSearchDynamic[component.name]"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="component.options"
          :placeholder="'Pilih ' + component.label"
          class="select-size-sm bg-white"
          multiple
          label="title"
        >
          <template #option="{ title, marketplace, count }">
            <span v-if="!marketplace"> {{ title }}</span>
            <span v-else>
              <b-img
                :src="
                  require('@/assets/images/marketplace/' + marketplace + '.png')
                "
                :alt="title"
                style="width: 24px; height: 24px"
                class="mr-1"
              />
              {{ title }}
            </span>
            <b-badge v-if="count" pill variant="info" class="ml-1">{{
              count
            }}</b-badge>
          </template>
        </v-select>

        <!-- date flatpickr -->
        <flat-pickr
          v-if="component.type === 'date'"
          :id="component.name"
          v-model="formSearchDynamic[component.name]"
          :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
          :placeholder="component.placeholder"
          class="form-control form-control-sm bg-white"
        />
      </b-form-group>

      <slot name="custom-component" />

      <b-form-group>
        <b-row>
          <b-col lg="6" md="6" sm="6">
            <b-button variant="primary" size="sm" block @click="search">
              <Feather-icon icon="SearchIcon" /> Cari
            </b-button>
          </b-col>
          <b-col v-if="isExportEnable" :lg="6" :md="6" :sm="6">
            <b-button variant="warning" size="sm" block>
              <Feather-icon icon="DownloadIcon" /> Export
            </b-button>
          </b-col>
          <b-col v-else :lg="6" :md="6" :sm="6">
            <b-button variant="danger" size="sm" block @click="resetForm">
              <Feather-icon icon="XIcon" /> Bersihkan
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <div v-else>
      <h1>No Components</h1>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BSidebar,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BButton,
  BBadge,
  BImg,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { defineComponent } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from '@axios';

export default defineComponent({
  components: {
    BRow,
    BCol,
    BSidebar,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BButton,
    flatPickr,
    BBadge,
    vSelect,
    BImg,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    components: {
      type: Array,
      default: () => [],
    },
    isExportEnable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formSearchDynamic: this.components.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
      }, {}),
    };
  },
  async mounted() {
    // if one of components contain store object key then fetch data from store
    if (this.$props.components.length === 0) return;

    this.$props.components.forEach(async component => {
      if (component.type === 'select' && component.store) {
        await this.$store.dispatch(
          `${component.store.module}/${component.store.action}`,
        );

        if (
          this.$store.state[component.store.module][component.store.key]
            .length > 0
        ) {
          // set value of component from store
          component.options =
            this.$store.state[component.store.module][component.store.key];
        }
      }

      if (
        (component.type === 'select' || component.type === 'select-multiple') &&
        component.url
      ) {
        const { data } = await axios.get(`${component.url}?showloading=0`);
        const key = data[component.key];

        const mapOptions = (k, c) => {
          // Define a mapping function to transform each item into an option object
          const mapFunction = item => {
            const option = {
              // Assign value from item based on the provided option key
              value: item[c.optionKey],
            };

            // Check if the component type is 'select-multiple' or 'select'
            if (c.type === 'select-multiple') {
              // Assign title based on the provided option text or key
              option.title = item[c.optionText] || item[c.optionKey];
            } else {
              // Assign text based on the provided option text or key
              option.text = item[c.optionText] || item[c.optionKey];
            }

            // Check if the component contains 'isMarketplace' flag
            if (c.isMarketplace) {
              // Assign marketplace from item's marketplaceName property
              option.marketplace = item.marketplaceName;
            }

            return option; // Return the constructed option object
          };

          let filtered = k; // Initialize filtered options with the provided key

          // If there's an option filter defined in the component
          if (c.optionFilter) {
            filtered = k.filter(item =>
              c.optionFilter.every(filter => item[filter.key] === filter.value),
            ); // Filter options based on the provided option filter
          }

          // Map each filtered item using the defined mapping function
          c.options = filtered.map(mapFunction); // Update the component options with the mapped options
        };

        mapOptions(key, component); // Call the mapOptions function with the provided key and component

        this.$forceUpdate(); // Force update the component to reflect the changes
      }
    });
  },
  methods: {
    // reset form
    resetForm() {
      this.formSearchDynamic = this.components.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
      }, {});
    },

    // search
    search() {
      // check every component if value is null or empty then remove from search
      Object.keys(this.formSearchDynamic).forEach(key => {
        if (
          this.formSearchDynamic[key] === null ||
          this.formSearchDynamic[key] === '' ||
          (Array.isArray(this.formSearchDynamic[key]) &&
            this.formSearchDynamic[key].length === 0)
        ) {
          delete this.formSearchDynamic[key];
        }
      });

      this.$emit('search', this.formSearchDynamic);
    },
  },
});
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
